// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .ConfirmDialog_dialogContainer__MA9WN {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .ConfirmDialog_dialog__IEjtE {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ConfirmDialog_dialogTitle__t0lzd {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .ConfirmDialog_dialogMessage__RKotC {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .ConfirmDialog_dialogButtons__6\\+1xG {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .ConfirmDialog_dialogButton__OqIQw {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .ConfirmDialog_dialogButton__OqIQw.ConfirmDialog_confirm__O\\+c\\+2 {
    background-color: #007bff;
    color: white;
  }
  
  .ConfirmDialog_dialogButton__OqIQw.ConfirmDialog_cancel__KJ9mR {
    background-color: #f0f0f0;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ConfirmDialog/ConfirmDialog.module.css"],"names":[],"mappings":"EAAE;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb","sourcesContent":["  .dialogContainer {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 1000;\n  }\n  \n  .dialog {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 400px;\n    width: 100%;\n    text-align: center;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .dialogTitle {\n    font-size: 18px;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #333;\n  }\n  \n  .dialogMessage {\n    font-size: 14px;\n    color: #555;\n    margin-bottom: 20px;\n  }\n  \n  .dialogButtons {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n  }\n  \n  .dialogButton {\n    padding: 8px 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 14px;\n  }\n  \n  .dialogButton.confirm {\n    background-color: #007bff;\n    color: white;\n  }\n  \n  .dialogButton.cancel {\n    background-color: #f0f0f0;\n    color: #333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": `ConfirmDialog_dialogContainer__MA9WN`,
	"dialog": `ConfirmDialog_dialog__IEjtE`,
	"dialogTitle": `ConfirmDialog_dialogTitle__t0lzd`,
	"dialogMessage": `ConfirmDialog_dialogMessage__RKotC`,
	"dialogButtons": `ConfirmDialog_dialogButtons__6+1xG`,
	"dialogButton": `ConfirmDialog_dialogButton__OqIQw`,
	"confirm": `ConfirmDialog_confirm__O+c+2`,
	"cancel": `ConfirmDialog_cancel__KJ9mR`
};
export default ___CSS_LOADER_EXPORT___;
