import React from 'react'
import styles from './User.module.css'

const User = ({user}) => {
  const authorImg = user.avatar !== null
      ? `https://dkf0tsr3xkyl9.cloudfront.net/${user.uuid}/${user.avatar}`
      : "https://cdn-icons-png.flaticon.com/512/9187/9187604.png";

  return (
    <div className={styles.popupContainer}>
      <div className={styles.user}>
          <img className={styles.avatar} src={authorImg} alt=""  width="32px" height="32px"/>
          <div className={styles.info}>
              <div className={styles.name}>{ user.nick }</div>
              <div className={styles.role}>{ user.role }</div>
          </div>
      </div>
      <div className={ styles.popup }>
        <div><img src={authorImg} alt="No avatar" className={styles.bigThumb}/></div>      
        <div className={ styles.bigNick }>{ user.nick }</div>
        <div className={ styles.bigName }>{ user.name }</div>
      </div>
    </div>
  )
}

export default User