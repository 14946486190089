import React, { useEffect } from "react";
import styles from "./Notification.module.css";
import check from "../../assets/icons/checkIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../redux/slices/notificationSlices";

const Notification = () => {
  const notification = useSelector((state) => state.notification.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!notification) {
      const timeoutId = setTimeout(() => {
        dispatch(setNotification(""));
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [dispatch, notification]);

  return (
    !!notification && (
      <div className={styles.notification}>
        <img src={check} alt="check" />
        <span>{notification}</span>
      </div>
    )
  );
};

export default Notification;
