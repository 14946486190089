import React from "react";
import styles from "./UsersTable.module.css";
import ItemLoader from "./UsersTableItem/UsersItemLoader";
import UsersTableItem from "./UsersTableItem";
import UsersTableTitle from "./UsersTableTitle";

const UsersTable = ({ users, column4 }) => {
  const loader = [];

  for (let i = 1; i <= 9; i++) {
    loader.push(<ItemLoader key={i} />);
  }

  return (
    <div className={styles.table}>
        <div className={styles.tableContainer}>
          <UsersTableTitle column4={ column4 }/>
          {users ? <UsersTableItem users={users} column4={column4}/> : <div className="container">{loader}</div>}
      </div>
    </div>
  );
};

export default UsersTable;
