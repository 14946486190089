// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsUsersTable_item__2KQqJ{
    display: flex;
    align-items: center;
    width: 12vw;
    padding: 4px 0;
    line-height: 18px;
}

.ReportsUsersTable_item__2KQqJ:first-child {
    width: 5vw;
}

.ReportsUsersTable_item__2KQqJ:nth-child(4) {
    width: 40vw;
}

.ReportsUsersTable_item__2KQqJ:last-child {
    width: 12vw;
}

`, "",{"version":3,"sources":["webpack://./src/components/ReportsUsersTable/ReportsUsersTable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".item{\n    display: flex;\n    align-items: center;\n    width: 12vw;\n    padding: 4px 0;\n    line-height: 18px;\n}\n\n.item:first-child {\n    width: 5vw;\n}\n\n.item:nth-child(4) {\n    width: 40vw;\n}\n\n.item:last-child {\n    width: 12vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `ReportsUsersTable_item__2KQqJ`
};
export default ___CSS_LOADER_EXPORT___;
