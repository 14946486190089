import styles from "./UsersItem.module.css";
import tableStyles from "../UsersTable.module.css";
import commonStyles from "../../Common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { post, remove } from "../../../api/api";
import { setNotification } from "../../../redux/slices/notificationSlices";
import { setCounter } from "../../../redux/slices/userSlices";
import User from "../../User/User";
import Contact from "../../Contact/Contact";
import FormattedDate from "../../FormattedDate/FormattedDate";

const UsersTableItem = ({users, column4}) => {
  const dispatch = useDispatch();
  const counter = useSelector((state) => state.user.counter);

  const delItem = async (email) => {
    const deleteItem = window.confirm(
      `Are you sure to delete ${ email }?`
    );
    const del = async (email) => {
      try {
        await remove(`/admin/user?email=${email}`);
        dispatch(setCounter(counter + 1))
        dispatch(setNotification("User deleted"));
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
    };
    deleteItem && del(email);
  };

  const confirmEmail = async (email) => {
    const confirmItem = window.confirm(
      `Are you sure to confirm ${ email }?`
    );
    const confirm = async (email) => {
      try {
        await post("/admin/confirm", {
          email: email,
        });
        dispatch(setCounter(counter + 1))
        dispatch(setNotification("User email confirmed"));
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
    };
    confirmItem && confirm(email);
  };

  const unblockEmail = async (email) => {
    const unblockItem = window.confirm(
      `Are you sure to unblock ${ email }?`
    );
    const unblock = async (email) => {
      try {
        await post("/admin/unblock", {
          email: email,
        });
        dispatch(setCounter(counter + 1))
        dispatch(setNotification("User email unblocked"));
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
    };
    unblockItem && unblock(email);
  };

  const activateEmail = async (email) => {
    const activateItem = window.confirm(`Are you sure to activate ${ email }?`);
    const activate = async (email) => {
      try {
        await post("/admin/activate", {
          email: email,
        });
        dispatch(setCounter(counter + 1))
        dispatch(setNotification("Account activated"));
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
    };
    activateItem && activate(email);
  };

  const ambassador = async (email) => {
    const ambassadorItem = window.confirm(`Are you sure to toggle ambassador flag of ${ email }?`);
    const ambassadorich = async (email) => {
      try {
        await post("/admin/ambassador", {
          email: email,
        });
        dispatch(setCounter(counter + 1))
        dispatch(setNotification("Account ambassador flag toggled"));
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
    };
    ambassadorItem && ambassadorich(email);
  };

  return (
    <div className={styles.tableTitle}>
      {users.map((user) => {        
        return (
          <div key={user.uuid} className={styles.titles}>
            <div className="container">
              <div className={styles.titlesContainer}>
                <div className={tableStyles.item}>
                  <FormattedDate dt={user.creation}/>
                </div>
                <div className={tableStyles.item}>
                  <Contact user={user}/>
                </div>
                <div className={tableStyles.item}>
                  <User user={user} />
                </div>
                <div className={user.ambassador ? tableStyles.ambassador : tableStyles.item}>
                  { column4.toLowerCase()==='phone' ? user.phone : user.subject}
                </div>
                <div className={`${commonStyles.actions} ${tableStyles.item}`}>
                  { user.state === 'BLOCKED' ? (
                      <button onClick={() => unblockEmail(user.email)} className={commonStyles.btnOk}>
                        Unblock
                      </button>
                    ) :
                    user.state === 'UNCONFIRMED' ? (
                      <button onClick={() => confirmEmail(user.email)} className={commonStyles.btnOk}>
                        Confirm
                      </button>
                    ) : user.state === 'PENDING' ? (
                      <button onClick={() => activateEmail(user.email)} className={commonStyles.btnOk}>
                        Activate
                      </button> 
                  ) : user.state === 'ACTIVE' ? (
                      <button onClick={() => ambassador(user.email)} className={user.ambassador ? commonStyles.btnDel : commonStyles.btnOk}>
                        Ambassador
                      </button>
                  ) : ""}
                  <button onClick={() => delItem(user.email)} className={commonStyles.btnDel}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UsersTableItem;
