// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact_user__sRTwW{
    width: 248px;
    display: flex;
    align-items: center;
    line-height: 15px;
}

.Contact_name__xbxVU {
    font-size: 14px;
    margin-bottom: 4px;
    color: #0D0D0D;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 220px;
    text-align: left;
}

.Contact_email__qed\\+o{
    font-size: 12px;
    color: #737373;
}

.Contact_valueValid__sjmca{
    color: #1D8102;
}

.Contact_valueInvalid__1IGlu{
    color: #EC002C;
}

`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".user{\n    width: 248px;\n    display: flex;\n    align-items: center;\n    line-height: 15px;\n}\n\n.name {\n    font-size: 14px;\n    margin-bottom: 4px;\n    color: #0D0D0D;\n    font-weight: bold;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    width: 220px;\n    text-align: left;\n}\n\n.email{\n    font-size: 12px;\n    color: #737373;\n}\n\n.valueValid{\n    color: #1D8102;\n}\n\n.valueInvalid{\n    color: #EC002C;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `Contact_user__sRTwW`,
	"name": `Contact_name__xbxVU`,
	"email": `Contact_email__qed+o`,
	"valueValid": `Contact_valueValid__sjmca`,
	"valueInvalid": `Contact_valueInvalid__1IGlu`
};
export default ___CSS_LOADER_EXPORT___;
