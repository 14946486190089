import React from "react";
import commonStyles from "../Common.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UsersNavigation = () => {
  const activeTotal = useSelector((state) => state.user.activeTotal);
  const pendingTotal = useSelector((state) => state.user.pendingTotal);
  const unconfirmedTotal = useSelector((state) => state.user.unconfirmedTotal);
  const blockedTotal = useSelector((state) => state.user.blockedTotal);
  const terminatedTotal = useSelector((state) => state.user.terminatedTotal);

  const nav = [
    { id: 0, name: "Active", href: "/active", count: activeTotal },
    { id: 1, name: "Pending", href: "/pending", count: pendingTotal},
    { id: 2, name: "Unconfirmed", href: "/unconfirmed", count: unconfirmedTotal },
    { id: 3, name: "Blocked", href: "/blocked", count: blockedTotal },
    { id: 4, name: "Terminated", href: "/terminated", count: terminatedTotal }
  ];
  
  return (
    <div className={commonStyles.navigation}>
      <div className="container">
        <div className={ commonStyles.navigationItems}>
          {nav.map(({ name, id, href, count }) => (
             <React.Fragment key={id}>
              <div className={commonStyles.navigationItem} >
                <Link
                  className={window.location.href.includes(`/users${href}`) ? commonStyles.linkActive : commonStyles.linkPassive}
                  to={`/users${href}`}
                > {name}</Link>
              </div>
              <div className={commonStyles.navigationItemTotal}>
                {count}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsersNavigation;
