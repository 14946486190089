// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarningMessage_warningContainer__YvXeo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 165, 0, 0.2);
  padding: 16px;
  border: 1px solid #ffa500;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.WarningMessage_warningText__AQuqr {
  color: #ff4500;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;   
}
`, "",{"version":3,"sources":["webpack://./src/components/WarningMessage/WarningMessage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,wCAAwC;EACxC,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".warningContainer {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: rgba(255, 165, 0, 0.2);\n  padding: 16px;\n  border: 1px solid #ffa500;\n  border-radius: 8px;\n  width: 80%;\n  max-width: 600px;\n}\n\n.warningText {\n  color: #ff4500;\n  font-size: 16px;\n  font-weight: bold;\n  text-align: center;\n  line-height: 1.5;   \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningContainer": `WarningMessage_warningContainer__YvXeo`,
	"warningText": `WarningMessage_warningText__AQuqr`
};
export default ___CSS_LOADER_EXPORT___;
