import React from 'react'
import styles from './Contact.module.css'

const Contact = ({user}) => {

  return (
    <div>
        <div className={styles.user}>
        <div className={styles.info}>
            <div className={styles.name}>{ user.name }</div>
            <div className={`${styles.email} ${ user.state==='UNCONFIRMED' ? styles.valueInvalid : styles.valueValid}`}>{user.email}</div>
        </div>
        </div>
    </div>
  )
}

export default Contact