import React from "react"
import ContentLoader from "react-content-loader"

const ItemLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1292}
    height={48}
    viewBox="0 0 1292 48"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="19" rx="5" ry="5" width="180" height="10" /> 
    <rect x="203.6" y="19" rx="5" ry="5" width="180" height="10" /> 
    <rect x="407.2" y="19" rx="5" ry="5" width="180" height="10" /> 
    <rect x="610.8" y="19" rx="5" ry="5" width="180" height="10" />
    <rect x="814.4" y="19" rx="5" ry="5" width="180" height="10" />
    <rect x="1018" y="19" rx="5" ry="5" width="118" height="10" />
    <rect x="1156" y="19" rx="5" ry="5" width="136" height="10" />
  </ContentLoader>
)

export default ItemLoader

