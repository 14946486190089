import React from "react";
import Header from "../components/Header";
import UsersSection from "../components/UsersSection";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination/Pagination";
import UsersTable from "../components/UsersTable";
import Notification from "../components/Notification/Notification";
import { setTerminatedLimit, setTerminatedPage } from "../redux/slices/userSlices";

const UsersTerminated = () => {
  const users = useSelector(state => state.user.terminatedUsers)
  const terminatedTotal = useSelector((state) => state.user.terminatedTotal);
  const page = useSelector(state => state.user.terminatedPage)
  const limit = useSelector(state => state.user.terminatedLimit)

  return (
    <div>
      <Header />
      <UsersSection />
      <UsersTable users = {users} column4={ "Sport" }/>
      {terminatedTotal !== 0 && <Pagination page={ page } setPage = { setTerminatedPage } limit = { limit }  setLimit = { setTerminatedLimit } total={ terminatedTotal }/>}
      <Notification />
    </div>
  );
};

export default UsersTerminated;
