import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logs: [],
  logsTotal: "",
  logsPage: 0,
  logsLimit: 10,
  logSearch: ""
};

export const logSlices = createSlice({
  name: "log",
  initialState,
  reducers: {
    setLogs(state, action) {
      state.logs = action.payload;
    },
    setLogsTotal(state, action) {
      state.logsTotal = action.payload;
    },
    setLogsPage(state, action) {
      state.logsPage = action.payload;
    },
    setLogsLimit(state, action) {
      state.logsLimit = action.payload;
    },
    setLogSearch(state, action) {
      state.logSearch = action.payload;
      state.logsPage = 0;
    }
  },
});

export const { setLogs, setLogsTotal, setLogsPage, setLogsLimit, setLogSearch } = logSlices.actions;

export default logSlices.reducer;
