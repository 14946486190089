import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notification: ""
};

export const notificationSlices = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action) {
      state.notification = action.payload;
    }
  },
});

export const { setNotification } = notificationSlices.actions;

export default notificationSlices.reducer;
