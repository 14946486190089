import React, { useEffect, useState } from "react";
import styles from "./Xarakiri.module.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import XarakiriLoginForm from "../XarakiriLoginForm";
import ConfirmDialog from "../ConfirmDialog";
import WarningMessage from "../WarningMessage";
import { remove } from "../../api/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/slices/notificationSlices";

const Xarakiri = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const name = Cookies.get("name");
  const email = Cookies.get("email");
  const avatar = Cookies.get("avatar");

  useEffect(() => {
    const storedToken = Cookies.get("token");
    setToken(storedToken);
  }, []);

  
  const handleLogout = async () => {
    try {
      await remove("/auth/logout");
      Cookies.remove("token");
      setToken(null);
    } catch (error) {
      console.error("Logout error: ", error)
      setToken(null);
    }
  };

  const handleDelete = async () => {
    setShowDialog(true);
  };

  const handleConfirm = async () => {
    try {
      await remove("/users");
      dispatch(setNotification("Account deleted"));
      Cookies.remove("token");
    } catch (error) {
      if(error.message) {
        dispatch(setNotification(error.message));
      } else {
        dispatch(setNotification("Account deletion failed, please try again"));
      }
    }
    setTimeout(() => {
      setToken(null);
    }, 3000);
    setShowDialog(false);
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  return (
    token ? (
      <div>
        <div className={styles.xarakiriContainer}>
          <div className={styles.userName}>
            <img className={styles.avatar} src={avatar} alt=""  width="32px" height="32px"/>
            &nbsp;
            <div className={styles.xarakiriInfo}>
              <span className={styles.label}>{name}</span>
              <span className={styles.email}>{email}</span>
            </div>
          </div>
          <div className={styles.button}>
            <Link style={{color: '#ff4500', fontSize: '18px'}} onClick={() => handleDelete()}>
              Delete account
            </Link>
            &nbsp;/&nbsp;
            <Link style={{color: 'white', fontSize: '18px'}} onClick={() => handleLogout()}>
              Exit
            </Link>
          </div>
        </div>
        <div>
          { showDialog && (
              <ConfirmDialog
                    title="Sportnest"
                    message="Are you sure you want to delete your account? This action is irreversible and all your data will be deleted from our servers."
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}/>
          )}
        </div>
        <div>
          <WarningMessage message='By clicking “Delete Account,” all data associated with your account (including any personal information, preferences, or content you have created) will be permanently removed from our servers. This action cannot be undone. If you wish to proceed, please click on "Delete account"' />
        </div>
      </div>    
  ) : <div>
        <XarakiriLoginForm />
  </div>)
};

export default Xarakiri;
