import React from 'react'
import styles from './ReportsCommentsTitle.module.css'
import tableStyles from '../ReportsCommentsTable.module.css'

const ReportsCommentsTitle = () => {
    const titles = [
        {id: 0, name: 'Created'},
        {id: 1, name: 'Reporter'},
        {id: 2, name: 'Target'},
        {id: 3, name: 'Post'},
        {id: 4, name: 'Comment'},
        {id: 5, name: 'Message'},
        {id: 6, name: 'Actions'},
    ]
  return (
    <div className="container">
        <div className={styles.tableTitle}>
            {titles.map((titles) => {
                return (
                <div key={titles.id} className={tableStyles.item}>
                    {titles.name}
                </div>
                );
            })}
        </div>
    </div>
  )
}

export default ReportsCommentsTitle