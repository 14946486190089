import { get } from "./api";
import { setNotification } from "../redux/slices/notificationSlices";
import { setActiveLimit, setActivePage, setBlockedLimit, setBlockedPage, setBlockedTotal, setBlockedUsers, setPendingLimit, setPendingPage, setTerminatedLimit, setTerminatedPage, setTerminatedTotal, setTerminatedUsers, setUnconfirmedLimit, setUnconfirmedPage } from "../redux/slices/userSlices";
import { setActiveUsers, setActiveTotal } from "../redux/slices/userSlices";
import { setPendingUsers, setPendingTotal } from "../redux/slices/userSlices";
import { setUnconfirmedTotal, setUnconfirmedUsers } from "../redux/slices/userSlices";
import { setUsersReports, setUsersTotal} from "../redux/slices/reportSlices";
import { setPostsReports, setPostsTotal} from "../redux/slices/reportSlices";
import { setCommentsReports, setCommentsTotal} from "../redux/slices/reportSlices";
import { setLogs, setLogsLimit, setLogsPage, setLogsTotal } from "../redux/slices/logSlices";

export const fetchData = async (dispatch, activePage, activeLimit, unconfirmedPage, unconfirmedLimit, pendingPage, pendingLimit, blockedPage, blockedLimit, terminatedPage, terminatedLimit, logsPage, logsLimit, userSearch, logSearch) => {
    try {
      const userData = await get(
        `admin/dashboard?activePage=${activePage}&activeLimit=${activeLimit}&unconfirmedPage=${unconfirmedPage}&unconfirmedLimit=${unconfirmedLimit}&pendingPage=${pendingPage}&pendingLimit=${pendingLimit}&blockedPage=${blockedPage}&blockedLimit=${blockedLimit}&terminatedPage=${terminatedPage}&terminatedLimit=${terminatedLimit}&logsPage=${logsPage}&logsLimit=${logsLimit}${userSearch ? `&userSearch=${userSearch}` : ""}${logSearch ? `&logSearch=${logSearch}` : ""}`
      );

      dispatch(setUnconfirmedUsers(userData.unconfirmed || []));
      dispatch(setActiveUsers(userData.active || []));
      dispatch(setPendingUsers(userData.pending || []));
      dispatch(setBlockedUsers(userData.blocked || []));
      dispatch(setTerminatedUsers(userData.terminated || []));
      dispatch(setLogs(userData.logs || []));

      dispatch(setUnconfirmedTotal(userData.unconfirmedTotal));
      dispatch(setActiveTotal(userData.activeTotal));
      dispatch(setPendingTotal(userData.pendingTotal));
      dispatch(setBlockedTotal(userData.blockedTotal));
      dispatch(setTerminatedTotal(userData.terminatedTotal));

      dispatch(setUsersReports(userData.userReports || []));
      dispatch(setUsersTotal(userData.userReportTotal));
      dispatch(setPostsReports(userData.postReports || []));
      dispatch(setPostsTotal(userData.postReportTotal));
      dispatch(setCommentsReports(userData.commentReports || []));
      dispatch(setCommentsTotal(userData.commentReportTotal));

      dispatch(setActiveLimit(userData.activeLimit));
      dispatch(setActivePage(userData.activePage));
      dispatch(setUnconfirmedLimit(userData.unconfirmedLimit));
      dispatch(setUnconfirmedPage(userData.unconfirmedPage));
      dispatch(setPendingLimit(userData.pendingLimit));
      dispatch(setPendingPage(userData.pendingPage));
      dispatch(setBlockedLimit(userData.blockedLimit));
      dispatch(setBlockedPage(userData.blockedPage));
      dispatch(setTerminatedLimit(userData.terminatedLimit));
      dispatch(setTerminatedPage(userData.terminatedPage));

      dispatch(setLogsLimit(userData.logsLimit));
      dispatch(setLogsPage(userData.logsPage));
      dispatch(setLogsTotal(userData.logsTotal));

    } catch (error) {
        if(error.response && error.response.data) {
          dispatch(setNotification(error.response.data.message))
        } else {
          console.error('Unexpected error occured: ', error)
        }
        window.location.href = "/";
    }
  };
