import React from "react";
import styles from "./Section.module.css";
import { useDispatch, useSelector } from "react-redux";
import UsersNavigation from "../UsersNavigation/UsersNavigation";
import { setUserSearch } from "../../redux/slices/userSlices";

const UsersSection = () => {
  const dispatch = useDispatch();
  const userSearch = useSelector((state) => state.user.userSearch);

  const handleInputChange = (e) => {
    dispatch(setUserSearch(e.target.value));
  };
    
  return (
    <div className={styles.section}>
      <div className="container">
        <div className={styles.sectionContainer}>
          <UsersNavigation />
          <div className={styles.search}>
            <input
              value={userSearch}
              onChange={handleInputChange}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersSection;
