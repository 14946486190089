import { Route, Routes } from "react-router-dom";
import UsersActive from "./pages/UsersActive";
import UsersPending from "./pages/UsersPending";
import UsersUnconfirmed from "./pages/UsersUnconfirmed";
import Login from "./pages/Login";
import ReportsUsers from "./pages/ReportsUsers";
import ReportsPosts from "./pages/ReportsPosts";
import ReportsComments from "./pages/ReportsComments";
import UsersBlocked from "./pages/UsersBlocked";
import UsersTerminated from "./pages/UsersTerminated";
import Logs from "./pages/Logs";
import UserXarakiri from "./pages/UserXarakiri";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/users">
          <Route index element={<UsersActive />} />
          <Route path="/users" element={<UsersActive />} />
        </Route>
        <Route path="/users/active">
          <Route index element={<UsersActive />} />
          <Route path="/users/active" element={<UsersActive />} />
        </Route>
        <Route path="/users/pending">
          <Route index element={<UsersPending />} />
          <Route path="/users/pending" element={<UsersPending />} />
        </Route>
        <Route path="/users/unconfirmed">
          <Route index element={<UsersUnconfirmed />} />
          <Route path="/users/unconfirmed" element={<UsersUnconfirmed />} />
        </Route>
        <Route path="/users/blocked">
          <Route index element={<UsersBlocked />} />
          <Route path="/users/blocked" element={<UsersBlocked />} />
        </Route>
        <Route path="/users/terminated">
          <Route index element={<UsersTerminated />} />
          <Route path="/users/terminated" element={<UsersTerminated />} />
        </Route>
        <Route path="/reports/accounts">
          <Route index element={<ReportsUsers />} />
          <Route path="/reports/accounts" element={<ReportsUsers />} />
        </Route>
        <Route path="/reports/post">
          <Route index element={<ReportsPosts />} />
          <Route path="/reports/post" element={<ReportsPosts />} />
        </Route>
        <Route path="/reports/comments">
          <Route index element={<ReportsComments />} />
          <Route path="/reports/comments" element={<ReportsComments />} />
        </Route>
        <Route path="/logs">
          <Route index element={<Logs />} />
          <Route path="/logs" element={<Logs />} />
        </Route>
        <Route path="/delete-account">
          <Route index element={<UserXarakiri />} />
          <Route path="/delete-account" element={<UserXarakiri />} />
        </Route>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="*">
          <Route index element={<Login />} />
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
