import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = 'https://api.sportnest.app';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000, 
  headers: {
    'Content-Type': 'application/json'
  }
});

if (Cookies.get("token")) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get("token")}`;
} else {
  delete axiosInstance.defaults.headers.common['Authorization'];
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
        console.error("Axios error, going home: ", error)
        window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const get = async (url, params = {}) => {
    const response = await axiosInstance.get(url, { params });
    return response.data;
};

export const post = async (url, data = {}) => {
    const response = await axiosInstance.post(url, data);
    return response.data;
};

export const remove = async (url) => {
    const response = await axiosInstance.delete(url);
    return response.data;
};

export default axiosInstance;
