import React from 'react'
import Header from '../components/Header'
import ReportsSection from '../components/ReportsSection'
import ReportsCommentsTable from '../components/ReportsCommentsTable/ReportsCommentsTable';
import Notification from '../components/Notification/Notification';

const ReportsComments = () => {

  return (
    <>
        <Header />
        <ReportsSection />
        <ReportsCommentsTable />
        <Notification />
    </>
    )
}

export default ReportsComments