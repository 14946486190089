import React from "react";
import styles from "./Section.module.css";
import ReportsNavigation from "../ReportsNavigation/ReportsNavigation";

const ReportsSection = () => {
  return (
    <div className={styles.section}>
      <div className="container">
        <div className={styles.sectionContainer}>
          <ReportsNavigation />
        </div>
      </div>
    </div>
  );
};

export default ReportsSection;
