// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_notification__SfISU{
    position: fixed;
    right: 64px;
    bottom: 64px;

    display: flex;
    align-items: center;

    min-width: 343px;
    padding: 8px;

    transition: all 1s ease-in-out;

    color: #fff;
    border-radius: 5px;
    background-color: #0D0D0D;

    font-size: 16px;
}

.Notification_notification__SfISU span{
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;;IAEnB,gBAAgB;IAChB,YAAY;;IAEZ,8BAA8B;;IAE9B,WAAW;IACX,kBAAkB;IAClB,yBAAyB;;IAEzB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".notification{\n    position: fixed;\n    right: 64px;\n    bottom: 64px;\n\n    display: flex;\n    align-items: center;\n\n    min-width: 343px;\n    padding: 8px;\n\n    transition: all 1s ease-in-out;\n\n    color: #fff;\n    border-radius: 5px;\n    background-color: #0D0D0D;\n\n    font-size: 16px;\n}\n\n.notification span{\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Notification_notification__SfISU`
};
export default ___CSS_LOADER_EXPORT___;
