import React, { useEffect } from "react";
import styles from "./Title.module.css";
import tableStyles from "../UsersTable.module.css"

const UsersTableTitle = ({column4}) => {

  const titles = [
    { id: 0, name: "Created" },
    { id: 1, name: "Name" },
    { id: 2, name: "Nick" },
    { id: 3, name: column4 },
    { id: 4, name: "Actions" },
  ];

  useEffect(() => {
    return () => {};
  }, []);


  return (
    <div className="container">
      <div className={styles.tableTitle}>
        {titles.map((titles) => {
          return (
            <div key={titles.id} className={tableStyles.item}>
              {titles.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsersTableTitle;
