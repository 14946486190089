// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xarakiri_xarakiriContainer__W453p{
    color: #FFF;
    background-color: #1D1D1D;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
    padding: 8px;
}

.Xarakiri_label__-auyw{
    font-size: 16px;
    font-weight: 800;
}

.Xarakiri_email__Xmyab{
    font-size: 14px;
    font-style: italic;
}

.Xarakiri_userName__XHcYa{
    display: flex;
    align-items: center;
}

.Xarakiri_button__oaVx8 span{
    font-weight: bold;
}

.Xarakiri_danger__BFXKy span{
    margin: 28px;
    font-weight: bold;
    color: #F10
}

.Xarakiri_avatar__5hgfn{
    margin-right: 12px;
    border-radius: 4px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Xarakiri/Xarakiri.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB;AACJ;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".xarakiriContainer{\n    color: #FFF;\n    background-color: #1D1D1D;\n    height: 64px;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    font-size: 16px;\n    padding: 8px;\n}\n\n.label{\n    font-size: 16px;\n    font-weight: 800;\n}\n\n.email{\n    font-size: 14px;\n    font-style: italic;\n}\n\n.userName{\n    display: flex;\n    align-items: center;\n}\n\n.button span{\n    font-weight: bold;\n}\n\n.danger span{\n    margin: 28px;\n    font-weight: bold;\n    color: #F10\n}\n\n.avatar{\n    margin-right: 12px;\n    border-radius: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xarakiriContainer": `Xarakiri_xarakiriContainer__W453p`,
	"label": `Xarakiri_label__-auyw`,
	"email": `Xarakiri_email__Xmyab`,
	"userName": `Xarakiri_userName__XHcYa`,
	"button": `Xarakiri_button__oaVx8`,
	"danger": `Xarakiri_danger__BFXKy`,
	"avatar": `Xarakiri_avatar__5hgfn`
};
export default ___CSS_LOADER_EXPORT___;
