import React from "react";
import ReportsPostsItem from "./ReportsPostsItem/ReportsPostsItem";
import ReportsPostsTitle from "./ReportsPostsTitle/ReportsPostsTitle";

const ReportsPostsTable = () => {
  return (
    <>
      <ReportsPostsTitle />
      <ReportsPostsItem />
    </>
  );
};

export default ReportsPostsTable;
