// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsPostsItem_tableTitle__\\+rqzz{
    color: #000;
    font-size: 14px;
}

.ReportsPostsItem_titles__SgRzt{    
    transition: ease-in .2s all;
}

.ReportsPostsItem_titlesContainer__wha9V{
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 13px;
}

.ReportsPostsItem_titles__SgRzt:hover {
    background-color: #F2F2F2;
}

.ReportsPostsItem_titlesContainer__wha9V button{
    padding: 8px 16px;

    cursor: pointer;
    transition: all .2s ease-in-out;

    border: 1px solid #D9D9D9;
    border-radius: 50px;
    background-color: #fff;

    font-size: 12px;
}

.ReportsPostsItem_titlesContainer__wha9V button:hover{
    color: #757575;
    background-color: #D9D9D9;
}

.ReportsPostsItem_titlesContainer__wha9V img{
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/ReportsPostsTable/ReportsPostsItem/ReportsPostsItem.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;;IAEjB,eAAe;IACf,+BAA+B;;IAE/B,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;;IAEtB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tableTitle{\n    color: #000;\n    font-size: 14px;\n}\n\n.titles{    \n    transition: ease-in .2s all;\n}\n\n.titlesContainer{\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    font-size: 13px;\n}\n\n.titles:hover {\n    background-color: #F2F2F2;\n}\n\n.titlesContainer button{\n    padding: 8px 16px;\n\n    cursor: pointer;\n    transition: all .2s ease-in-out;\n\n    border: 1px solid #D9D9D9;\n    border-radius: 50px;\n    background-color: #fff;\n\n    font-size: 12px;\n}\n\n.titlesContainer button:hover{\n    color: #757575;\n    background-color: #D9D9D9;\n}\n\n.titlesContainer img{\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTitle": `ReportsPostsItem_tableTitle__+rqzz`,
	"titles": `ReportsPostsItem_titles__SgRzt`,
	"titlesContainer": `ReportsPostsItem_titlesContainer__wha9V`
};
export default ___CSS_LOADER_EXPORT___;
