import React from "react";
import styles from "./Section.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setLogSearch } from "../../redux/slices/logSlices";

const LogsSection = () => {
  const dispatch = useDispatch();
  const logSearch = useSelector((state) => state.log.logSearch);

  const handleInputChange = (e) => {
    dispatch(setLogSearch(e.target.value));
    console.log('Log search: ', e.target.value)
  };
    
  return (
    <div className={styles.section}>
      <div className="container">
        <div className={styles.sectionContainer}>
          <div></div>
          <div className={styles.search}>
            <input
              value={logSearch}
              onChange={handleInputChange}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsSection;
