import React from "react";
import styles from "./Post.module.css";

const Post = ({ post }) => {
  const postImg = post.media !== null
      ? `https://dkf0tsr3xkyl9.cloudfront.net/${post.user.uuid}/${post.media}.thumb`
      : "https://cdn-icons-png.flaticon.com/512/9187/9187604.png";  
    
  return (
    <div className={styles.popupContainer}>
      <div><img src={postImg} alt="post" className={styles.thumb}/></div>      
      <div className={styles.title}>{ post.title }</div>
      <div className={ styles.popup }>
        <div><img src={postImg} alt="post" className={styles.bigThumb}/></div>      
        <div>{ post.title }</div>
      </div>
    </div>
  );
};

export default Post;
