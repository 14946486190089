// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormattedDate_info__qM7L\\+{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./src/components/FormattedDate/FormattedDate.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".info{\n    display: flex;\n    align-items: flex-end;\n    flex-wrap: wrap;\n    flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `FormattedDate_info__qM7L+`
};
export default ___CSS_LOADER_EXPORT___;
