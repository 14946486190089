// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogsTable_table__3JPfs{
    margin-bottom: 56px;
}

.LogsTable_item__HUxr\\+{
    display: flex;
    align-items: center;
    width: 10vw;
    padding: 4px 0;
}

.LogsTable_item__HUxr\\+:first-child {
    width: 10vw;
}

.LogsTable_item__HUxr\\+:last-child{
    width: 90vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/LogsTable/LogsTable.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".table{\n    margin-bottom: 56px;\n}\n\n.item{\n    display: flex;\n    align-items: center;\n    width: 10vw;\n    padding: 4px 0;\n}\n\n.item:first-child {\n    width: 10vw;\n}\n\n.item:last-child{\n    width: 90vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `LogsTable_table__3JPfs`,
	"item": `LogsTable_item__HUxr+`
};
export default ___CSS_LOADER_EXPORT___;
