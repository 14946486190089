import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import passwordShield from "../../assets/icons/passwordShieldIcon.svg";
import eyeClosed from "../../assets/icons/eyeClosedIcon.svg";
import nickname from "../../assets/icons/nicknameIcon.svg";
import Cookies from "js-cookie";
import { post } from "../../api/api";

const LoginForm = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await post(
        "/auth/admin",
        {
          email: email,
          password: password,
        }
      );
      Cookies.set('token', loginResponse.token);
      Cookies.set('nick', loginResponse.nick);
      
      window.location.href = "/users/active";
    } catch (error) {
        setError(error.response?.data.message)
    }
  };

  return (
    <div className={styles.loginForm}>
      <div className="container">
        <div className={styles.loginFormContainer}>
          <div className={styles.title}>Sportnest Admin Dashboard</div>
          <form onSubmit={handleSubmit}>
            <div className={styles.email}>
              <img src={nickname} alt="email" />
              <input
                id="email-field"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="current-password"
                placeholder="Enter your email"
              />
            </div>
            <div className={styles.email}>
              <img src={passwordShield} alt="password shield" />
              <input
                id="password-field"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
                placeholder="Enter your password"
              />
              <div
                type="text"
                className={styles.show}
                onClick={() => setShow(!show)}
              >
                <img src={eyeClosed} alt="eye closed" />
              </div>
            </div>
            {error && <div className={styles.error}>{error}</div>}
            <div className={styles.btnContainer}>
              {/* <Link to="users"> */}
              <button type="submit" className={styles.btn}>
                Log in
              </button>
              {/* </Link> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
