import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeUsers: [],
  pendingUsers: [],
  unconfirmedUsers: [],
  blockedUsers: [],
  terminatedUsers: [],
  activeTotal: "",
  pendingTotal: "",
  unconfirmedTotal: "",
  blockedTotal: "",
  terminatedTotal: "",
  activePage: 0,
  activeLimit: 10,
  unconfirmedPage: 0,
  unconfirmedLimit: 10,
  pendingPage: 0,
  pendingLimit: 10,
  blockedPage: 0,
  blockedLimit: 10,
  terminatedPage: 0,
  terminatedLimit: 10,
  userSearch: "",
  counter: 0
};

export const userSlices = createSlice({
  name: "user",
  initialState,
  reducers: {
    setActiveUsers(state, action) {
      state.activeUsers = action.payload;
    },
    setPendingUsers(state, action) {
      state.pendingUsers = action.payload;
    },
    setUnconfirmedUsers(state, action) {
      state.unconfirmedUsers = action.payload;
    },
    setBlockedUsers(state, action) {
      state.blockedUsers = action.payload;
    },
    setTerminatedUsers(state, action) {
      state.terminatedUsers = action.payload;
    },
    setActiveTotal(state, action) {
      state.activeTotal = action.payload;
    },
    setPendingTotal(state, action) {
      state.pendingTotal = action.payload;
    },
    setUnconfirmedTotal(state, action) {
      state.unconfirmedTotal = action.payload;
    },
    setBlockedTotal(state, action) {
      state.blockedTotal = action.payload;
    },
    setTerminatedTotal(state, action) {
      state.terminatedTotal = action.payload;
    },
    setActivePage(state, action) {
      state.activePage = action.payload;
    },
    setActiveLimit(state, action) {
      state.activeLimit = action.payload;
    },
    setUnconfirmedPage(state, action) {
      state.unconfirmedPage = action.payload;
    },
    setUnconfirmedLimit(state, action) {
      state.unconfirmedLimit = action.payload;
    },
    setPendingPage(state, action) {
      state.pendingPage = action.payload;
    },
    setPendingLimit(state, action) {
      state.pendingLimit = action.payload;
    },
    setBlockedPage(state, action) {
      state.blockedPage = action.payload;
    },
    setBlockedLimit(state, action) {
      state.blockedLimit = action.payload;
    },
    setTerminatedPage(state, action) {
      state.terminatedPage = action.payload;
    },
    setTerminatedLimit(state, action) {
      state.terminatedLimit = action.payload;
    },
    setUserSearch(state, action) {
      state.userSearch = action.payload;
      state.activePage = 0;
      state.unconfirmedPage = 0;
      state.pendingPage = 0;
      state.blockedPage = 0;
      state.terminatedPage = 0;
    },
    setCounter(state, action) {
      state.counter = action.payload;
    }
  },
});

export const { setActiveTotal, setPendingTotal, setUnconfirmedTotal, setBlockedTotal, setTerminatedTotal,
  setActiveUsers, setPendingUsers, setUnconfirmedUsers, setBlockedUsers, setTerminatedUsers, 
  setActivePage, setActiveLimit, 
  setUnconfirmedPage, setUnconfirmedLimit, 
  setPendingPage, setPendingLimit, 
  setBlockedPage, setBlockedLimit, 
  setTerminatedPage, setTerminatedLimit, 
  setUserSearch, setCounter } =
  userSlices.actions;

export default userSlices.reducer;
