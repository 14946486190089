import React from "react";
import Header from "../components/Header";
import ReportsSection from "../components/ReportsSection";
import ReportsPostsTable from "../components/ReportsPostsTable/ReportsPostsTable";
import Notification from "../components/Notification/Notification";

const ReportsPosts = () => {

  return (
    <>
      <Header />
      <ReportsSection />
      <ReportsPostsTable />
      <Notification />
    </>
  );
};

export default ReportsPosts;
