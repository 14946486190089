// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_tableTitle__XIOkN{
    display: flex;
    align-items: center;

    color: #737373;
    border-bottom: 1px solid #D9D9D9;

    font-size: 14px;
}

`, "",{"version":3,"sources":["webpack://./src/components/LogsTable/LogsTableTitle/Title.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,cAAc;IACd,gCAAgC;;IAEhC,eAAe;AACnB","sourcesContent":[".tableTitle{\n    display: flex;\n    align-items: center;\n\n    color: #737373;\n    border-bottom: 1px solid #D9D9D9;\n\n    font-size: 14px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTitle": `Title_tableTitle__XIOkN`
};
export default ___CSS_LOADER_EXPORT___;
