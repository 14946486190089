import React from "react";
import Header from "../components/Header";
import UsersSection from "../components/UsersSection";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination/Pagination";
import UsersTable from "../components/UsersTable";
import Notification from "../components/Notification/Notification";
import { setPendingLimit, setPendingPage } from "../redux/slices/userSlices";

const UsersPending = () => {
  const users = useSelector(state => state.user.pendingUsers)
  const pendingTotal = useSelector((state) => state.user.pendingTotal);
  const page = useSelector(state => state.user.pendingPage)
  const limit = useSelector(state => state.user.pendingLimit)

  return (
    <div>
      <Header />
      <UsersSection />
      <UsersTable users={ users } column4={ "Phone" }/>
      {pendingTotal !== 0 && <Pagination page={ page } setPage = { setPendingPage } limit = { limit }  setLimit = { setPendingLimit } total={ pendingTotal }/>}
      <Notification />
    </div>
  );
};

export default UsersPending;
