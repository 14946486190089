import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ReportsPostsItem.module.css";
import tableStyles from "../ReportsPostsTable.module.css";
import commonStyles from "../../Common.module.css";
import { post } from "../../../api/api";
import { setNotification } from "../../../redux/slices/notificationSlices";
import User from "../../User/User";
import Post from "../../Post/Post";
import { setCounter } from "../../../redux/slices/userSlices";
import FormattedDate from "../../FormattedDate/FormattedDate";

const ReportsPostsItem = () => {
  const dispatch = useDispatch();
  const postsReports = useSelector((state) => state.report.postsReports);
  const counter = useSelector((state) => state.user.counter);

  const handleBlock = (uuid, state) => {
    const blockItem = window.confirm(`Are you sure to set the state of report to: ${state}?`);

    const block = async () => {
      try {
        await post(`/admin/report`, {
          item: uuid.toString(),
          type: "POST",
          state: state,
        });
        dispatch(setCounter(counter + 1));
        dispatch(setNotification(`Post report: ${state}`));
      } catch (error) {
        console.log(error.response.data.message);
        dispatch(setNotification(error.response.data.message));
      }
    };

    blockItem && block();
  };

  return (
    <div className={styles.tableTitle}>
      {postsReports.map((report) => {        
        return (
          <div key={report.uuid} className={styles.titles}>
            <div className="container">
              <div className={styles.titlesContainer}>
                <div className={tableStyles.item}>
                  <FormattedDate dt={report.creation} />
              </div>
                <div className={tableStyles.item}>
                  <User user={report.author} />
                </div>
                <div className={tableStyles.item}>
                  <User user={report.target} />
                </div>
                <div className={tableStyles.item}>
                  <Post post={report.post} />
                </div>
                <div className={tableStyles.item}>
                  { report.message }
              </div>
                <div className={`${commonStyles.actions} ${tableStyles.item}`}>
                  <button onClick={() => handleBlock(report.uuid, "REJECTED")} className={commonStyles.btnOk}>
                    Ignore
                  </button>
                  <button onClick={() => handleBlock(report.uuid, "RESOLVED")} className={commonStyles.btnDel}>
                    Resolve
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReportsPostsItem;
