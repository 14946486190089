import React from "react";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination/Pagination";
import Notification from "../components/Notification/Notification";
import LogsTable from "../components/LogsTable";
import { setLogsPage, setLogsLimit } from "../redux/slices/logSlices";
import LogsSection from "../components/LogsSection";

const Logs = () => {
  const logs = useSelector(state => state.log.logs)
  const logsTotal = useSelector(state => state.log.logsTotal)
  const page = useSelector(state => state.log.logsPage)
  const limit = useSelector(state => state.log.logsLimit)

  return (
    <div>
      <Header />
      <LogsSection />
      <LogsTable logs={ logs } />
      {logsTotal !== 0 && <Pagination page={ page } setPage = { setLogsPage } limit = { limit }  setLimit = { setLogsLimit } total={ logsTotal }/>}
      <Notification />
    </div>
  );
};

export default Logs;
