import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersReports: [],
  usersTotal: "",
  usersPage: "",
  usersLimit: 10,

  postsReports: [],
  postsTotal: "",
  postsPage: "",
  postsLimit: 10,

  commentsReports: [],
  commentsTotal: "",
  commentsPage: "",
  commentsLimit: 10,
};

export const reportSlices = createSlice({
  name: "report",
  initialState,
  reducers: {
    setUsersReports(state, action) {
      state.usersReports = action.payload;
    },
    setUsersTotal(state, action) {
      state.usersTotal = action.payload;
    },
    setUsersPage(state, action) {
      state.usersPage = action.payload;
    },
    setUsersLimit(state, action) {
      state.usersLimit = action.payload;
    },
    setPostsReports(state, action) {
      state.postsReports = action.payload;
    },
    setPostsTotal(state, action) {
      state.postsTotal = action.payload;
    },
    setPostsPage(state, action) {
      state.postsPage = action.payload;
    },
    setPostsLimit(state, action) {
      state.postsLimit = action.payload;
    },

    setCommentsReports(state, action) {
      state.commentsReports = action.payload;
    },
    setCommentsTotal(state, action) {
      state.commentsTotal = action.payload;
    },
    setCommentsPage(state, action) {
      state.commentsPage = action.payload;
    },
    setCommentsLimit(state, action) {
      state.commentsLimit = action.payload;
    },
  },
});

export const { setUsersTotal, setUsersReports, setUsersPage, setUsersLimit, setPostsTotal, setPostsReports, setPostsPage, setPostsLimit, setCommentsTotal, setCommentsReports, setCommentsPage, setCommentsLimit } =
  reportSlices.actions;

export default reportSlices.reducer;
