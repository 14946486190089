// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersTable_table__ol6N3{
    margin-bottom: 56px;
}

.UsersTable_item__Uurxd{
    display: flex;
    align-items: center;
    width: 15vw;
    height: 40px;
    padding: 4px 0;
}

.UsersTable_ambassador__Mkr7L{
    display: flex;
    align-items: center;
    width: 15vw;
    height: 40px;
    padding: 4px 4px;
    background-color: #fddc5c;
    border-radius: 10px;
}

.UsersTable_item__Uurxd:first-child {
    width: 5vw;
}

.UsersTable_item__Uurxd:nth-child(2) {
    width: 30vw;
}

.UsersTable_item__Uurxd:nth-child(3) {
    width: 25vw;
}

.UsersTable_item__Uurxd:last-child{
    width: 15vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/UsersTable/UsersTable.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".table{\n    margin-bottom: 56px;\n}\n\n.item{\n    display: flex;\n    align-items: center;\n    width: 15vw;\n    height: 40px;\n    padding: 4px 0;\n}\n\n.ambassador{\n    display: flex;\n    align-items: center;\n    width: 15vw;\n    height: 40px;\n    padding: 4px 4px;\n    background-color: #fddc5c;\n    border-radius: 10px;\n}\n\n.item:first-child {\n    width: 5vw;\n}\n\n.item:nth-child(2) {\n    width: 30vw;\n}\n\n.item:nth-child(3) {\n    width: 25vw;\n}\n\n.item:last-child{\n    width: 15vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `UsersTable_table__ol6N3`,
	"item": `UsersTable_item__Uurxd`,
	"ambassador": `UsersTable_ambassador__Mkr7L`
};
export default ___CSS_LOADER_EXPORT___;
