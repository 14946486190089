import React from "react";
import Header from "../components/Header";
import ReportsSection from "../components/ReportsSection";
import ReportsUsersTable from "../components/ReportsUsersTable/ReportsUsersTable";
import Notification from "../components/Notification/Notification";

const ReportsUsers = () => {

  return (
    <>
      <Header />
      <ReportsSection />
      <ReportsUsersTable />
      <Notification />
    </>
  );
};

export default ReportsUsers;
