import React from "react";
import styles from "./WarningMessage.module.css";

const WarningMessage = ({ message }) => {
  return (
    <div className={styles.warningContainer}>
      <div className={styles.warningText}>
        {message}
      </div>
    </div>
  );
};
export default WarningMessage;
