import React from "react";
import Xarakiri from "../components/Xarakiri";
import Notification from "../components/Notification/Notification";

const UserXarakiri = () => {

  return (
    <div>
      <Xarakiri />
      <Notification />
    </div>
  );
};

export default UserXarakiri;
