import { configureStore } from '@reduxjs/toolkit'
import user from './slices/userSlices';
import report from './slices/reportSlices';
import notification from "./slices/notificationSlices"
import log from "./slices/logSlices"

export const store = configureStore({
  reducer: {
    user, notification, report, log
  },
})