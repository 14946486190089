import React from "react";
import styles from "./ConfirmDialog.module.css";

function ConfirmDialog({ title = "Confirmation", message, onConfirm, onCancel }) {
  return (
    <div className={styles.dialogContainer}>
      <div className={styles.dialog}>
        <h2 className={styles.dialogTitle}>{title}</h2>
        <p className={styles.dialogMessage}>{message}</p>
        <div className={styles.dialogButtons}>
          <button className={`${styles.dialogButton} ${styles.cancel}`} onClick={onCancel}>
            Cancel
          </button>
          <button className={`${styles.dialogButton} ${styles.confirm}`} onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDialog;
