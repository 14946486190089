import React, { useEffect, useState } from "react";
import styles from "./Xarakiri.module.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import XarakiriLoginForm from "../XarakiriLoginForm";
import { remove } from "../../api/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/slices/notificationSlices";

const Xarakiri = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);

  const name = Cookies.get("name");
  const email = Cookies.get("email");
  const avatar = Cookies.get("avatar");

  useEffect(() => {
    const storedToken = Cookies.get("token");
    setToken(storedToken);
  }, []);

  
  const handleLogout = async () => {
    try {
      await remove("/auth/logout");
      Cookies.remove("token");
      setToken(null);
    } catch (error) {
      console.error("Logout error: ", error)
      setToken(null);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(`You are going to delete your account and all data, with no recovery`);

    const actualDelete = async () => {
      try {
        await remove("/users");
        dispatch(setNotification("Account deleted"));
        Cookies.remove("token");
      } catch (error) {
        dispatch(setNotification(error.response.data.message));
      }
      setTimeout(() => {
        setToken(null);
      }, 3000);
    }
    confirmDelete && actualDelete();
  };

  return (
    token ? (
    <div className={styles.xarakiriContainer}>
      <div className={styles.userName}>
        <img className={styles.avatar} src={avatar} alt=""  width="32px" height="32px"/>
        &nbsp;
        <span className={styles.label}>{name}</span>
        &nbsp; (&nbsp;
        <span className={styles.email}>{email}</span>
        &nbsp;)
      </div>
      <div className={styles.button}>
        <Link style={{color: '#ff9966', fontSize: '18px'}} onClick={() => handleDelete()}>
          Delete account
        </Link>
        &nbsp;/&nbsp;
        <Link style={{color: 'white', fontSize: '18px'}} onClick={() => handleLogout()}>
          Exit
        </Link>
      </div>
    </div>
  ) : <div>
        <XarakiriLoginForm />
  </div>)
};

export default Xarakiri;
