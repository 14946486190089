import React from "react";
import styles from "./LogsTable.module.css";
import ItemLoader from "./LogsTableItem/LogsItemLoader";
import LogsTableItem from "./LogsTableItem";
import LogsTableTitle from "./LogsTableTitle";

const LogsTable = ({ logs }) => {
  const loader = [];

  for (let i = 1; i <= 9; i++) {
    loader.push(<ItemLoader key={i} />);
  }

  return (
    <div className={styles.table}>
        <div className={styles.tableContainer}>
          <LogsTableTitle/>
          {logs ? <LogsTableItem logs={ logs }/> : <div className="container">{loader}</div>}
      </div>
    </div>
  );
};

export default LogsTable;
