// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsCommentsTable_item__4OBt4{
    display: flex;
    align-items: center;
    width: 10vw;
    padding: 4px 0;
    line-height: 18px;
}

.ReportsCommentsTable_item__4OBt4:first-child {
    width: 5vw;
}

.ReportsCommentsTable_item__4OBt4:nth-child(4) {
    width: 7vw;
}

.ReportsCommentsTable_item__4OBt4:nth-child(5) {
    width: 15vw;
}

.ReportsCommentsTable_item__4OBt4:nth-child(6) {
    width: 25vw;
}

.ReportsCommentsTable_item__4OBt4:last-child {
    width: 12vw;
}

`, "",{"version":3,"sources":["webpack://./src/components/ReportsCommentsTable/ReportsCommentsTable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".item{\n    display: flex;\n    align-items: center;\n    width: 10vw;\n    padding: 4px 0;\n    line-height: 18px;\n}\n\n.item:first-child {\n    width: 5vw;\n}\n\n.item:nth-child(4) {\n    width: 7vw;\n}\n\n.item:nth-child(5) {\n    width: 15vw;\n}\n\n.item:nth-child(6) {\n    width: 25vw;\n}\n\n.item:last-child {\n    width: 12vw;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `ReportsCommentsTable_item__4OBt4`
};
export default ___CSS_LOADER_EXPORT___;
