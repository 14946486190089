// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Section_section__ElKSj{
    height: 60px;

    color: #FFF;
    border-top: #ffffff2f solid 1px;
    background-color: #0D0D0D;
}

.Section_sectionContainer__Vs57q{
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 60px;
}

.Section_title__ALyEV{
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 32px;
}

.Section_title__ALyEV span{
    margin-left: 16px;

    opacity: 0.5;

    font-size: 24px;
}

.Section_search__jXt5z{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 304px;
    height: 32px;
    padding: 0 4px 0 18px;

    border-radius: 50px;
    background-color: #fff;
}

.Section_search__jXt5z input{
    width: 100%;
    padding-right: 10px;

    border: none;
    outline: none;
}

.Section_search__jXt5z button{
    margin: 0;
    padding: 0;

    cursor: pointer;

    border: none;
    outline: none;
    background: none;

    line-height: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UsersSection/Section.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,WAAW;IACX,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,eAAe;AACnB;;AAEA;IACI,iBAAiB;;IAEjB,YAAY;;IAEZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,YAAY;IACZ,YAAY;IACZ,qBAAqB;;IAErB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,mBAAmB;;IAEnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,UAAU;;IAEV,eAAe;;IAEf,YAAY;IACZ,aAAa;IACb,gBAAgB;;IAEhB,cAAc;AAClB","sourcesContent":[".section{\n    height: 60px;\n\n    color: #FFF;\n    border-top: #ffffff2f solid 1px;\n    background-color: #0D0D0D;\n}\n\n.sectionContainer{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    height: 60px;\n}\n\n.title{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    font-size: 32px;\n}\n\n.title span{\n    margin-left: 16px;\n\n    opacity: 0.5;\n\n    font-size: 24px;\n}\n\n.search{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    width: 304px;\n    height: 32px;\n    padding: 0 4px 0 18px;\n\n    border-radius: 50px;\n    background-color: #fff;\n}\n\n.search input{\n    width: 100%;\n    padding-right: 10px;\n\n    border: none;\n    outline: none;\n}\n\n.search button{\n    margin: 0;\n    padding: 0;\n\n    cursor: pointer;\n\n    border: none;\n    outline: none;\n    background: none;\n\n    line-height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Section_section__ElKSj`,
	"sectionContainer": `Section_sectionContainer__Vs57q`,
	"title": `Section_title__ALyEV`,
	"search": `Section_search__jXt5z`
};
export default ___CSS_LOADER_EXPORT___;
