import React from "react";
import ReportsUsersItem from "./ReportsUsersItem/ReportsUsersItem";
import ReportsUsersTitle from "./ReportsUsersTitle/ReportsUsersTitle";

const ReportsUsersTable = () => {
  return (
    <>
      <ReportsUsersTitle />
      <ReportsUsersItem />
    </>
  );
};

export default ReportsUsersTable;
