import styles from "./LogsItem.module.css";
import tableStyles from "../LogsTable.module.css";
import FormattedDate from "../../FormattedDate/FormattedDate";

const LogsTableItem = ({ logs }) => {

  return (
    <div className={styles.tableTitle}>
      {logs.map((log) => {        
        return (
          <div key={log.uuid} className={styles.titles}>
            <div className="container">
              <div className={styles.titlesContainer}>
                <div className={tableStyles.item}>
                  <FormattedDate dt={log.creation}/>
                </div>
                <div className={tableStyles.item}>
                  { log.text }
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LogsTableItem;
