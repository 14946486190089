// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__HXQOm{
    height: 64px;

    color: #FFF;
    background-color: #0D0D0D;

    font-size: 16px;
}

.Header_headerLight__Dcw3W{
    border-bottom: 1px solid #D9D9D9;
    background-color: none;
}

.Header_headerContainer__kKEW0{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 64px;
}

.Header_userName__q0v6S{
    display: flex;
    align-items: center;
}

.Header_userName__q0v6S span{
    font-weight: bold;
}

.Header_appVersion__bAgyS{
    position: absolute;
    right: 10px;
    bottom: 10px;

    opacity: 0.3;

    font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,WAAW;IACX,yBAAyB;;IAEzB,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;;IAEZ,YAAY;;IAEZ,iBAAiB;AACrB","sourcesContent":[".header{\n    height: 64px;\n\n    color: #FFF;\n    background-color: #0D0D0D;\n\n    font-size: 16px;\n}\n\n.headerLight{\n    border-bottom: 1px solid #D9D9D9;\n    background-color: none;\n}\n\n.headerContainer{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    width: 100%;\n    height: 64px;\n}\n\n.userName{\n    display: flex;\n    align-items: center;\n}\n\n.userName span{\n    font-weight: bold;\n}\n\n.appVersion{\n    position: absolute;\n    right: 10px;\n    bottom: 10px;\n\n    opacity: 0.3;\n\n    font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__HXQOm`,
	"headerLight": `Header_headerLight__Dcw3W`,
	"headerContainer": `Header_headerContainer__kKEW0`,
	"userName": `Header_userName__q0v6S`,
	"appVersion": `Header_appVersion__bAgyS`
};
export default ___CSS_LOADER_EXPORT___;
