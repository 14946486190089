import React from "react";
import ReportsCommentsItem from "./ReportsCommentsItem/ReportsCommentsItem";
import ReportsCommentsTitle from "./ReportsCommentsTitle/ReportsCommentsTitle";

const ReportsCommentsTable = () => {
  return (
    <>
      <ReportsCommentsTitle />
      <ReportsCommentsItem />
    </>
  );
};

export default ReportsCommentsTable;
