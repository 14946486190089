// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Post_popupContainer__ULR7g {
    display: inline-block;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    line-height: 15px;
}

.Post_title__9ZsvI{
    display: inline-block;
    font-size: 11px;
    color: #737373;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60px;
    text-align: center;
}

.Post_thumb__lJEN9{
    border-radius: 4px;
    object-fit: cover;
    width: 64px;
    height: 40px;
    margin-top: 6px;
}

.Post_bigThumb__kjizV{
    border-radius: 4px;
    object-fit: cover;
    width: 300px;
    height: 160px;
}

.Post_popup__7jK-F {
    visibility: hidden;
    background-color: #858585;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 100%; /* Position the popup above the text */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content;
    max-width: 400px; /* Adjust max width as needed */
}
  
.Post_popupContainer__ULR7g:hover .Post_popup__7jK-F {
    visibility: visible;
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Post/Post.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,cAAc;IACd,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY,EAAE,sCAAsC;IACpD,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB,EAAE,+BAA+B;AACrD;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".popupContainer {\n    display: inline-block;\n    position: relative;\n    align-items: center;\n    flex-wrap: wrap;\n    flex-direction: column;\n    justify-content: center;\n    cursor: pointer;\n    line-height: 15px;\n}\n\n.title{\n    display: inline-block;\n    font-size: 11px;\n    color: #737373;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    width: 60px;\n    text-align: center;\n}\n\n.thumb{\n    border-radius: 4px;\n    object-fit: cover;\n    width: 64px;\n    height: 40px;\n    margin-top: 6px;\n}\n\n.bigThumb{\n    border-radius: 4px;\n    object-fit: cover;\n    width: 300px;\n    height: 160px;\n}\n\n.popup {\n    visibility: hidden;\n    background-color: #858585;\n    color: #fff;\n    text-align: center;\n    border-radius: 6px;\n    padding: 8px;\n    position: absolute;\n    z-index: 1;\n    bottom: 100%; /* Position the popup above the text */\n    left: 50%;\n    transform: translateX(-50%);\n    opacity: 0;\n    transition: opacity 0.3s;\n    width: max-content;\n    max-width: 400px; /* Adjust max width as needed */\n}\n  \n.popupContainer:hover .popup {\n    visibility: visible;\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `Post_popupContainer__ULR7g`,
	"title": `Post_title__9ZsvI`,
	"thumb": `Post_thumb__lJEN9`,
	"bigThumb": `Post_bigThumb__kjizV`,
	"popup": `Post_popup__7jK-F`
};
export default ___CSS_LOADER_EXPORT___;
