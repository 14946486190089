import React, { useEffect } from "react";
import commonStyles from "../Common.module.css";
import styles from "./Header.module.css";
import logo from "../../assets/img/logo.png";
import logOut from "../../assets/icons/logOut.png";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { remove } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../api/data";

const Header = ({ type }) => {
  const dispatch = useDispatch();

  const storedToken = Cookies.get("token");
  const nick = Cookies.get("nick");
  const usersTotal = useSelector((state) => state.user.activeTotal) + 
    useSelector((state) => state.user.unconfirmedTotal) + useSelector((state) => state.user.pendingTotal) +
    useSelector((state) => state.user.blockedTotal) + useSelector((state) => state.user.terminatedTotal);

  const reportsTotal = useSelector((state) => state.report.usersTotal) + 
    useSelector((state) => state.report.postsTotal) + useSelector((state) => state.report.commentsTotal);

  const logsTotal = useSelector((state) => state.log.logsTotal);

  const counter = useSelector((state) => state.user.counter);

  const activePage = useSelector((state) => state.user.activePage);
  const activeLimit = useSelector((state) => state.user.activeLimit);
  const unconfirmedPage = useSelector((state) => state.user.unconfirmedPage);
  const unconfirmedLimit = useSelector((state) => state.user.unconfirmedLimit);
  const pendingPage = useSelector((state) => state.user.pendingPage);
  const pendingLimit = useSelector((state) => state.user.pendingLimit);
  const blockedPage = useSelector((state) => state.user.blockedPage);
  const blockedLimit = useSelector((state) => state.user.blockedLimit);
  const terminatedPage = useSelector((state) => state.user.terminatedPage);
  const terminatedLimit = useSelector((state) => state.user.terminatedLimit);
  const logsPage = useSelector((state) => state.log.logsPage);
  const logsLimit = useSelector((state) => state.log.logsLimit);
  const logSearch = useSelector((state) => state.log.logSearch);
  const userSearch = useSelector((state) => state.user.userSearch);
  
  useEffect(() => {
    fetchData(dispatch, activePage, activeLimit, unconfirmedPage, unconfirmedLimit, pendingPage, pendingLimit, 
      blockedPage, blockedLimit, terminatedPage, terminatedLimit, logsPage, logsLimit, userSearch, logSearch)
    console.log('Data fetch, counter: ', counter)  
  }, [dispatch, activePage, activeLimit, unconfirmedPage, unconfirmedLimit, pendingPage, pendingLimit, 
      blockedPage, blockedLimit, terminatedPage, terminatedLimit, logsPage, logsLimit, userSearch, logSearch, counter]);

  const handleLogout = async () => {
    try {
      await remove("/auth/logout");
      Cookies.remove("token");
      window.location.href = "/";
    } catch (error) {
      console.error("Logout error: ", error)
      window.location.href = "/";
    }
  };

  return (
    <div className={type === "light" ? styles.headerLight : styles.header}>
      <div className="container">
        <div className={styles.headerContainer}>
          <div className={styles.logo}>
            <Link to={storedToken ? "/users/active" : ""}>
              <img src={logo} alt="Logo" width="40px" />
            </Link>
          </div>

          {type === "light" ? (
            <div></div>
          ) : (
            <>
              <div className={commonStyles.navigation}>
                <div className="container">
                  <div className={commonStyles.navigationItems}>
                    <div className={commonStyles.navigationItem}>
                      <Link to="/users/active" className={ window.location.href.includes("users") ? commonStyles.linkActive : commonStyles.linkPassive}>Users</Link>
                    </div>
                    <div className={commonStyles.navigationItemTotal}>
                      {usersTotal}
                    </div>
                    <div className={commonStyles.navigationItem}>
                      <Link to="/reports/accounts" className={ window.location.href.includes("reports") ? commonStyles.linkActive : commonStyles.linkPassive}>Reports</Link>
                    </div>
                    <div className={commonStyles.navigationItemTotal}>
                      {reportsTotal}
                    </div>
                    <div className={commonStyles.navigationItem}>
                      <Link to="/logs" className={ window.location.href.includes("logs") ? commonStyles.linkActive : commonStyles.linkPassive}>Logs</Link>
                    </div>
                    <div className={commonStyles.navigationItemTotal}>
                      {logsTotal}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.userName}>
                Logged in as &nbsp;
                <span>{nick}</span>
                &nbsp;
                <Link onClick={() => handleLogout()}>
                  <img src={logOut} alt="log out" />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.appVersion}>1.0</div>
    </div>
  );
};

export default Header;
