import React from "react";
import commonStyles from "../Common.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportsNavigation = () => {
  const usersTotal = useSelector((state) => state.report.usersTotal);
  const postsTotal = useSelector((state) => state.report.postsTotal);
  const commentsTotal = useSelector((state) => state.report.commentsTotal);

  const nav = [
    { id: 0, name: "Accounts", href: "/accounts", count: usersTotal },
    { id: 1, name: "Posts", href: "/post", count: postsTotal},
    { id: 2, name: "Comments", href: "/comments", count: commentsTotal },
  ];
  
  return (
    <>
      <div className={commonStyles.navigation}>
        <div className="container">
          <div className={ commonStyles.navigationItems}>
            {nav.map(({ name, id, href, count }) => (
              <React.Fragment key={id}>
                <div className={commonStyles.navigationItem}>
                  <Link
                    className={window.location.href.includes(`/reports${href}`) ? commonStyles.linkActive : commonStyles.linkPassive}
                    key={id}
                    to={`/reports${href}`}
                  >{name}</Link>
                </div>
                <div className={commonStyles.navigationItemTotal}>
                  {count}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsNavigation;
