import React from "react";
import moment from "moment";
import styles from "./FormattedDate.module.css";

const FormattedDate = ({ dt }) => {
    const up = moment(dt).format("DD MMM")
    const down = moment(dt).format("YYYY")
    return (
      <div className={styles.info}>
        <div>
          {up}
        </div>
        <div>
          {down}
        </div>
      </div>
    );
  };

  export default FormattedDate;
