import React, { useState } from "react";
import styles from "./Pagination.module.css";
import { useDispatch } from "react-redux";
import caret from "../../assets/icons/caretIcon.svg";
import arrowLeft from "../../assets/icons/caretIcon.svg";
import arrowRight from "../../assets/icons/caretIcon.svg";

const Pagination = ({page, setPage, limit, setLimit, total}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const pages = [];

  for (let i = 1; i <= Math.ceil(total / limit); i++) {
    pages.push(i);
  }

  const [start, setStart] = useState(0);

  const showNumbers = (start) => {
    return pages.slice(start, start + 3);
  };

  const handlePrevious = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  const handleNext = () => {
    if (start < pages.length - 3) {
      setStart(start + 1);
    }
  };

  const handleChange = (i) => {
    dispatch(setLimit(i));
    setOpen(false);
    setStart(0)
    dispatch(setPage(0))
  };

  const from = page * limit + 1;
  const to = limit + page * limit;

  const limitItem = [5, 10, 15, 20];

  const changePage = (number, id) => {
    dispatch(setPage(number - 1))
  }


  return (
    <div className={styles.paginationContainer}>
      <div className="container">
        <div className={styles.navigation}>
          <div className={styles.pageItems}>
            <div>Rows per page</div>
            <div className={styles.open}>
              <button className={styles.btn} onClick={() => setOpen(!open)}>
                <div>{limit}</div>
                <div>
                  <img src={caret} alt="caret" />
                </div>
              </button>
              <div className={styles.limitItems}>
                {open &&
                  limitItem.map((i) => (
                    <div
                      className={styles.limitItem}
                      key={i}
                      onClick={() => handleChange(i)}
                    >
                      {i}
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <span>Showing </span>
              {from} – {total > to ? to : total} of {total}
            </div>
          </div>
          <div className={styles.pages}>
            <div>
              <img height="16px" className={styles.arrowLeft} src={arrowLeft} onClick={handlePrevious} alt="arrowLeft" />
            </div>
            {showNumbers(start).map((number, id) => (
              <div className={`${styles.pageNumber} ${number === page + 1 && styles.activePage}`} onClick={() => changePage(number, id)} key={number}>
                {number}
              </div>
            ))}
            <div>
              <img height="16px" className={styles.arrowRight} src={arrowRight} onClick={handleNext} alt="arrowRight" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
