import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import {store} from './redux/store'

window.onerror = (message, source, lineno, colno, error) => {
  console.error('Global Error:', message, 'at', source, lineno, colno, error);
  return true;
};
window.addEventListener('unhandledrejection', event => {
  // You could also send the error to a logging service here, e.g., Sentry
  console.error('Unhandled Promise Rejection:', event.reason);
  return true;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router future={{ v7_startTransition: true }}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);